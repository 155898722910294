import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-more-projects',
  templateUrl: './more-projects.component.html',
  styleUrls: ['./more-projects.component.scss'],
})
export class MoreProjectsComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  redirect(project: Project, event) {
    const id = event.target.id;
    if (id == 'demoLink' || id == 'ghLink') {
      return;
    }
    if (project.demoLink) {
      window.open(project.demoLink, '_blank');
    } else if (project.ghLink) {
      window.open(project.ghLink, '_blank');
    }
  }
}

export class Project {
  public ghLink: string;
  public demoLink: string;
}
