<section class="section contact" id="contact">
  <div class="container">
    <div class="section-box">
      <div class="mb-4" data-aos="fade-up" data-aos-duration="1200">
        <h3 class="contact-pre-title big-subtitle">05. {{ 'Contact.Pretitle' | translate }}</h3>
        <h4 class="e-font contact-title big-title">
          {{ 'Contact.Title' | translate }}
        </h4>
      </div>
      <p data-aos="fade-up" data-aos-duration="1200">
        {{ 'Contact.Content' | translate }}
      </p>
      <div class="mt-5">
        <a href="https://www.linkedin.com/in/dario-schaffner/" target="_blank">
          <i class="fab fa-linkedin-in"></i>
        </a>
      </div>
    </div>
  </div>
</section>
