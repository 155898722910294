<nav [@animateMenu] class="navbar main-navbar on-top" [class.nav-shadow]="this.pageYPosition > 0">
  <div class="container">
    <a class="navbar-brand text-light" [routerLink]="'en'">
      <img src="assets/images/logo.png" width="50" />
    </a>
    <ul ngbNav #nav="ngbNav" class="menu-ul">
      <li ngbNavItem>
        <a ngbNavLink (click)="scroll('about')"
          ><span class="nav-number">01. </span>
          <span class="underline nav-text"> {{ 'Header.Item1' | translate }}</span></a
        >
        <ng-template ngbNavContent>First content</ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink (click)="scroll('jobs')"
          ><span class="nav-number">02. </span>
          <span class="underline nav-text"> {{ 'Header.Item2' | translate }}</span></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink (click)="scroll('educations')"
          ><span class="nav-number">03. </span>
          <span class="underline nav-text"> {{ 'Header.Item3' | translate }}</span></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink (click)="scroll('projects')"
          ><span class="nav-number">04. </span>
          <span class="underline nav-text"> {{ 'Header.Item4' | translate }}</span></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink (click)="scroll('contact')"
          ><span class="nav-number">05. </span>
          <span class="underline nav-text"> {{ 'Header.Item5' | translate }}</span></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>
      <!-- <li ngbNavItem>
        <a class="main-btn cv-btn" href="https://github.com/dar1ooo" target="_blank">
          {{ 'Header.cvBtn' | translate }}
        </a>
      </li> -->
      <li>
        <div ngbDropdown class="d-inline-block">
          <div class="language-container" id="dropdownForm1" ngbDropdownToggle>
            <img
              *ngIf="languageFormControl.value == 'en'"
              width="20px"
              src="https://www.worldometers.info/img/flags/us-flag.gif"
              alt="English"
            />
            <span *ngIf="languageFormControl.value == 'en'" class="flag-text">English</span>

            <img
              *ngIf="languageFormControl.value == 'de'"
              width="20px"
              src="https://s3.amazonaws.com/images.wpr.com/flag-pages/png250/de.png"
              alt="German"
            />
            <span *ngIf="languageFormControl.value == 'de'" class="flag-text">German</span>
            <svg class="arrow-language" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
            </svg>
          </div>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1">
            <div
              (click)="changeLanguage('de')"
              style="border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important"
              class="language-container language-container-selector active"
              [class.active]="languageFormControl.value == 'de'"
            >
              <img width="20px" src="https://s3.amazonaws.com/images.wpr.com/flag-pages/png250/de.png" alt="German" />
              <span class="flag-text">German</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-check2 arrow-active"
                viewBox="0 0 16 16"
              >
                <path
                  d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </div>
            <div
              (click)="changeLanguage('en')"
              style="
                margin-top: 1px !important;
                border-top-left-radius: 0px !important;
                border-top-right-radius: 0px !important;
              "
              class="language-container language-container-selector"
              [class.active]="languageFormControl.value == 'en'"
            >
              <img width="20px" src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English" />
              <span class="flag-text">English</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-check2 arrow-active"
                viewBox="0 0 16 16"
              >
                <path
                  d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="menu-wrapper">
      <div
        [class.animate]="responsiveMenuVisible"
        (click)="responsiveMenuVisible = !responsiveMenuVisible"
        class="hamburger-menu"
      ></div>
    </div>
    <div class="menu-responsive" [ngStyle]="{ 'pointer-events': !responsiveMenuVisible ? 'none' : '' }">
      <aside [class.aside-show]="responsiveMenuVisible" [class.nav-shadow]="this.pageYPosition > 0" class="on-top">
        <nav>
          <ol>
            <li>
              <a (click)="scroll('about')"> <span>01. </span> {{ 'Header.Item1' | translate }} </a>
            </li>
            <li>
              <a (click)="scroll('jobs')"> <span>02. </span> {{ 'Header.Item2' | translate }} </a>
            </li>
            <li>
              <a (click)="scroll('educations')"> <span>03. </span> {{ 'Header.Item3' | translate }} </a>
            </li>
            <!-- <li>
              <a (click)="scroll('proyects')"> <span>04. </span> {{ 'Header.Item4' | translate }} </a>
            </li>
            <li>
              <a (click)="scroll('proyects')"> <span>05. </span> {{ 'Header.Item5' | translate }} </a>
            </li> -->
            <li>
              <a href="https://github.com/dar1ooo" class="main-btn cv-btn" target="_blank">
                {{ 'Header.cvBtn' | translate }}
              </a>
            </li>
            <li>
              <div class="language-container">
                <div
                  (click)="changeLanguage('de')"
                  style="border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important"
                  class="language-container language-container-selector active"
                  [class.active]="languageFormControl.value == 'de'"
                >
                  <img src="https://s3.amazonaws.com/images.wpr.com/flag-pages/png250/de.png" alt="German" />
                  <span class="flag-text">German</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check2 arrow-active"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
                    />
                  </svg>
                </div>
                <div
                  (click)="changeLanguage('en')"
                  style="
                    margin-top: 1px !important;
                    border-top-left-radius: 0px !important;
                    border-top-right-radius: 0px !important;
                  "
                  class="language-container language-container-selector"
                  [class.active]="languageFormControl.value == 'en'"
                >
                  <img src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English" />
                  <span class="flag-text">English</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check2 arrow-active"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <!-- <li>
                            <img src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                        </li> -->
          </ol>
        </nav>
      </aside>
    </div>
  </div>
</nav>
