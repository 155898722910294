<footer>
  <div [@animateFooter]>
    <ul class="footer-left-bar d-none d-md-block">
      <li>
        <a href="https://github.com/dar1ooo" target="_blank">
          <i class="fab fa-github"></i>
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/dario-schaffner/" target="_blank">
          <i class="fab fa-linkedin-in"></i>
        </a>
      </li>
    </ul>
    <div class="footer-right-bar d-none d-md-block">
      <a>darioschaffner7@gmail.com </a>
    </div>
  </div>

  <div class="footer-credits text-center">
    <a href="https://github.com/dar1ooo" target="_blank" rel="nofollow noopener noreferrer">
      <div>Built with Angular by Dario</div>
    </a>
  </div>
</footer>
