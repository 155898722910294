import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit {
  active = 0;

  ngOnInit(): void {}

  openLink(link: string): void {
    if (link) window.open(link, '_blank');
  }
}
