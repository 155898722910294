<section class="section educations" id="educations">
  <div class="container">
    <div class="section-box">
      <div class="about-title mb-5" data-aos="fade-up-left" data-aos-duration="1200">
        <h3 class="e-font section-title">
          <span class="code-font n-section-title">03.</span> {{ 'Educations.Title' | translate }}
        </h3>
      </div>
      <div class="educations-tabs" data-aos="fade-up-left" data-aos-duration="1200">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" orientation="vertical" class="nav-tabs educations-tabs">
          <li
            *ngFor="let education of 'Educations.Schools' | translate; let i = index"
            [title]="education.Tab"
            [ngbNavItem]="i"
          >
            <a ngbNavLink>{{ education.Tab }}</a>
            <ng-template ngbNavContent>
              <h4 class="title-tab-content">
                {{ education.Title }} | <span class="company-tab-content">{{ education.Tab }}</span>
              </h4>
              <h5 class="education-time">{{ education.Date }}</h5>
              <p
                *ngFor="let jeducationDescriptionParagraph of education.Description; let i = index"
                class="education-description"
                [innerHTML]="jeducationDescriptionParagraph | translate"
              ></p>
              <span></span>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</section>
