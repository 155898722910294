import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  language = '';

  constructor(
    public translateService: TranslateService,
    private location: Location
  ) {}

  initLanguage() {
    this.translateService.addLangs(['en', 'de']);
    let language = localStorage.getItem('appLanguage');

    if (!language) {
      language = navigator.language || (navigator as any).userLanguage;
      language = language.split('-').includes('de') ? 'de' : 'en';
      localStorage.setItem('appLanguage', language);
    }

    this.translateService.setDefaultLang(language);

    this.location.go(language);

    this.language = language;
  }

  changeLanguage(language: 'de' | 'en') {
    localStorage.setItem('appLanguage', language);
    this.translateService.setDefaultLang(language);
    this.location.go(language);
    this.language = language;
  }
}
