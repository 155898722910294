<section class="section banner" data-aos="fade-in" data-aos-duration="1200">
  <div [@bannerTrigger] class="container">
    <div class="section-box-banner">
      <div class="content">
        <div>
          <h1>{{ 'Banner.Pretitle' | translate }}</h1>
        </div>
        <div class="banner-title">
          <h2>Dario Schaffner</h2>
          <h4>Junior Software Engineer</h4>
        </div>
        <div class="banner-description">
          <p [innerHTML]="'Banner.Description' | translate" class="mt-4"></p>
        </div>
      </div>
    </div>
  </div>
</section>
