<section class="section about" id="about">
  <div class="container">
    <div class="section-box">
      <div class="about-title" data-aos="fade-up">
        <h3 class="section-title"><span class="n-section-title">01.</span> {{ 'AboutMe.Title' | translate }}</h3>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-4 mb-md-0">
          <div class="about-description">
            <p
              *ngFor="let text of 'AboutMe.Paragraphs' | translate"
              [innerHTML]="text"
              data-aos="fade-up"
              data-aos-duration="1000"
            ></p>
            <ul class="skills-list" data-aos="fade-up">
              <li class="skill-element" data-aos="fade-up" data-aos-duration="900">
                <span class="underline"><a href="https://angular.io/" target="_blank">Angular </a></span>
              </li>
              <li class="skill-element" data-aos="fade-up" data-aos-duration="900">
                <span class="underline"><a href="https://servicestack.net/" target="_blank">ServiceStack </a></span>
              </li>
              <li class="skill-element" data-aos="fade-up" data-aos-duration="900">
                <span class="underline"><a href="https://rxjs.dev/" target="_blank">RxJS </a></span>
              </li>
              <li class="skill-element" data-aos="fade-up" data-aos-duration="900">
                <span class="underline"><a href="https://html.com/" target="_blank">HTML &amp; (S)CSS</a> </span>
              </li>
              <li class="skill-element" data-aos="fade-up" data-aos-duration="900">
                <span class="underline"><a href="https://www.docker.com/" target="_blank">Docker </a></span>
              </li>
              <li class="skill-element" data-aos="fade-up" data-aos-duration="900">
                <span class="underline"><a href="https://nodejs.org/en/" target="_blank">Node.js </a></span>
              </li>
              <li class="skill-element" data-aos="fade-up" data-aos-duration="900">
                <span class="underline"><a href="https://flutter.dev/learn" target="_blank">Flutter </a></span>
              </li>
              <li class="skill-element" data-aos="fade-up" data-aos-duration="900">
                <span class="underline"><a href="https://dart.dev/" target="_blank">Dart </a></span>
              </li>
              <li class="skill-element" data-aos="fade-up" data-aos-duration="900">
                <span class="underline"><a href="https://www.mysql.com/" target="_blank">MySQL </a></span>
              </li>
              <li class="skill-element" data-aos="fade-up" data-aos-duration="900">
                <span class="underline"><a href="https://www.mongodb.com/" target="_blank">MongoDB </a></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-left" data-aos-duration="1500">
          <div class="about-img-container">
            <img class="rounded" width="300" height="300" src="assets/images/me2.jpg" alt="José Andrés" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
